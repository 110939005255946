<template>
    <ParentPage page-type="maltStrategyParentPage"></ParentPage>
</template>
<script lang="ts" setup>
    import {useWebsitePageView, definePageMeta} from '#imports';
    import ParentPage from '~/components/ParentPage.vue';

    useWebsitePageView('cms', 'maltstrategy');

    definePageMeta({
        layout: 'maltstrategy',
    });
</script>
